
import { defineComponent, reactive, ref } from "vue";
import OnlineCareIntro from "@/components/onlineCare/Intro.vue";
import SecondOpinionIntro from "@/components/secondOpinion/Intro.vue";
import DateNameFilter from "@/components/common/DateNameFilter.vue";
import { IDataNameFilter } from "@/models/common";
// import RequestCard from "@/components/common/Request/RequestCard.vue";
import Doctors from "@/components/common/Doctors/Doctors.vue";
import { PossibleTypes } from "@/models/doctor";
import Button from "primevue/button";
import ConfirmDialog from "@/components/primevueCustom/ConfirmDialog.vue";
import { useConfirm } from "primevue/useconfirm";
import Checkbox from "primevue/checkbox";
import RequestStep from "@/components/common/Request/RequestStep.vue";
import { RequestKindTypes, RequestSteps } from "@/models/request";
import SetResidentRegistrationNumber from "@/components/mypage/SetResidentRegistrationNumber.vue";
import Scroll from "@/components/common/Scroll.vue";

export default defineComponent({
  setup() {
    const state: IDataNameFilter = reactive({
      from: "",
      to: "",
      name: "",
    });
    const confirm = useConfirm();
    const withInfo = () => {
      confirm.require({
        message: "해당 예약을 취소하시겠습니까? 취소 후 복구가 불가능합니다.",
        header: "예약취소 및 환불신청",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "신청하기",
        rejectLabel: "닫기",
        group: "withInfo",
        accept: () => {},
        reject: () => {},
      });
    };
    const defaultButton = () => {
      confirm.require({
        message: "접수하기를 누르시면 접수가 완료됩니다.",
        header: "접수하시겠습니까?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "네",
        rejectLabel: "아니오",
        group: "defaultButton",
        accept: () => {},
        reject: () => {},
      });
    };
    const isConfirmButton = () => {
      confirm.require({
        message: "한 번 더 클릭하여 관심의사를 해제할 수 있습니다.",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "확인",
        group: "isConfirm",
        accept: () => {},
        reject: () => {},
      });
    };

    const checked = ref(false);

    return {
      state,
      PossibleTypes,
      withInfo,
      defaultButton,
      isConfirmButton,
      checked,
      RequestKindTypes,
      RequestSteps,
    };
  },
  components: {
    OnlineCareIntro,
    SecondOpinionIntro,
    DateNameFilter,
    // RequestCard,
    Doctors,
    Button,
    ConfirmDialog,
    Checkbox,
    RequestStep,
    SetResidentRegistrationNumber,
    Scroll,
  },
});
